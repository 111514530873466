import * as Sentry from '@sentry/astro'

Sentry.init({
	dsn: 'https://41af3648f5eb434c87d6d0a03509bc82@o337482.ingest.us.sentry.io/1871908',

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],

	// Define how likely traces are sampled. Adjust this value in production,
	// or use tracesSampler for greater control.
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
})
